import { r as w } from "./theme-sections-Co0nR_Yu.js";
import { F as b } from "./index-ZT_jCi5J.js";
import "./flickity-imagesloaded-C4rgTJ6g.js";
import "./imagesloaded-DIwzLWAh.js";
const u = (t) => {
    t.forEach((o) => {
      const e = o.querySelectorAll("button, a, input, select, textarea");
      o.ariaHidden === "true"
        ? e.forEach((s) => {
            s.tabIndex = -1;
          })
        : e.forEach((s) => {
            s.tabIndex = 0;
          });
    });
  },
  l = {
    sliderContainer: "[data-slider-container]",
    slide: "[data-slide]",
    videoSlide: '[data-slide="video"]',
    videoWrapper: "[data-background-video]",
    video: "[data-video]",
  },
  c = {
    singleSlide: "single-slide",
    selectedSlide: "is-selected",
    videoPlaying: "video-playing",
  },
  m = "hero-slider";
var f, g, S;
const E =
    ((S =
      (g = (f = window.Shopify) == null ? void 0 : f.theme) == null
        ? void 0
        : g.sections) == null
      ? void 0
      : S.registered) || {},
  A = typeof E[m] > "u";
A &&
  w(m, {
    onLoad() {
      const t = this.container,
        o = t.dataset.sectionSettings || "{}";
      this.settings = JSON.parse(o);
      const e = t.querySelector(l.sliderContainer);
      if (!e) return;
      const s = e.querySelectorAll(l.slide),
        v = e.querySelectorAll(l.videoSlide),
        h = window.matchMedia(`(min-width: ${window.theme.breakpoints.md}px)`);
      this.mediaType = h.matches ? "desktop" : "mobile";
      const r = (i) => {
        var y;
        const a =
          (y = s[i]) == null ? void 0 : y.querySelectorAll(l.videoWrapper);
        !a ||
          a.length === 0 ||
          a.forEach((n) => {
            n.querySelectorAll("video").forEach(async (d) => {
              const p = d.dataset.mediaType || "both";
              if (p === "both" || p === this.mediaType) {
                d.autoplay = !0;
                try {
                  await d.play();
                } catch (k) {
                  console.warn("Failed to play video:", k);
                }
              } else d.pause();
            }),
              n.classList.add(c.videoPlaying);
          });
      };
      if (
        (h.addEventListener("change", () => {
          if (
            ((this.mediaType = h.matches ? "desktop" : "mobile"),
            e.classList.contains(c.singleSlide))
          ) {
            r(0);
            return;
          }
          this.flickity && r(this.flickity.selectedIndex);
        }),
        e.classList.contains(c.singleSlide))
      ) {
        const i = t.querySelector(l.slide);
        i == null || i.classList.add(c.selectedSlide), r(0);
        return;
      }
      this.flickity = new b(e, {
        prevNextButtons: !1,
        autoPlay: this.settings.autoplay,
        pageDots: this.settings.include_pager_dots,
        adaptiveHeight: !0,
        watchCSS: !0,
        setGallerySize: this.settings.slider_height_type === "natural",
        wrapAround: this.settings.infinite,
        accessibility: !1,
        dragThreshold: 30,
        on: {
          ready() {
            u(s), r(0);
          },
          change(i) {
            u(s),
              r(i),
              v.forEach((a) => {
                if (a === this.selectedElement) return;
                a.querySelectorAll("video").forEach((n) => n.pause());
              }),
              !this.options.wrapAround &&
                i === this.slides.length - 1 &&
                (this.stopPlayer(),
                e.classList.remove("hero-slider--autoplay"));
          },
          staticClick() {
            this.stopPlayer(), e.classList.remove("hero-slider--autoplay");
          },
          dragStart() {
            this.stopPlayer(), e.classList.remove("hero-slider--autoplay");
          },
        },
      });
    },
    onBlockSelect(t) {
      if (!Shopify.designMode) return;
      this.settings.autoplay && this.flickity.pausePlayer();
      const o = Array.from(t.target.parentElement.children).indexOf(t.target);
      this.flickity.select(o);
    },
    onDeselect() {
      Shopify.designMode &&
        this.settings.autoplay &&
        this.flickity.unpausePlayer();
    },
  });
